// ------------------------------------------------------------ FONT FACE DECLARATIONS

// @font-face {
//   font-family: "Mulish Light";
//   src: url("../fonts/Mulish-VariableFont_wght.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish Regular";
//   src: local("Mulish-Regular"),
//     url("../fonts/Mulish-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish Italic";
//   src: local("Mulish-Italic"),
//     url("../fonts/Mulish-Italic.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish Medium";
//   src: local("Mulish-Medium"),
//     url("../fonts/Mulish-Medium.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish SemiBold";
//   src: local("Mulish-SemiBold"),
//     url("../fonts/Mulish-SemiBold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish Bold";
//   src: local("Mulish-Bold"),
//     url("../fonts/Mulish-Bold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Mulish ExtraBold";
//   src: local("Mulish-ExtraBold"),
//     url("../fonts/Mulish-ExtraBold.ttf") format("truetype");
// }

@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-VariableFont_wght.ttf") format("truetype");
}

// ------------------------------------------------------------ ELEMENT DEFAULTS

* {
  font-family: "Mulish";
}

// HEADINGS

h1 {
  font-size: 2rem; // 32px
  font-weight: bold;
}

h2 {
  font-size: 2.8125rem; // 45px
  font-weight: bold;
}

h3 {
  font-size: 4.3125rem; // 69px
  font-weight: bold;
}

h4 {
  font-size: 5.5rem; // 88px
  font-weight: bold;
  line-height: 1.1;
}

// BODY

p {
  font-size: 0.75rem; // 12px
}

// OTHER

input, textarea, select {
  font-size: 0.75rem; // 12px
}

label {
  font-size: 0.75rem; // 12px
  font-weight: bold;
}

a, .link {
  color: black;
  text-decoration: none;
}

// ------------------------------------------------------------ BUTTONS

.Button_primary {
  font-weight: bold;
  
  button {
    font-size: 1.125rem; // 18px
    font-weight: bold;
  }
}

.Button_secondary {
  button {
    font-size: 1rem; // 16px
    font-weight: bold;
  }
}

// ------------------------------------------------------------ TYPOGRAPHY HELPERS

// WEIGHTS
.light {
  font-weight: lighter;
}
.regular {

}
.italic {
  font-style: italic;
}
.medium {
  
}
.semibold {
  
}
.bold {
  font-weight: bold;
}
.extrabold {
  font-weight: bolder;
}
.bold-italic {
  font-weight: bold;
  font-style: italic;
}

// ALIGNMENT
.align_left {
  text-align: left;
}
.align_center {
  text-align: center;
}
.align_right {
  text-align: right;
}

// CURSOR
.pointer {
  cursor: pointer;
}

// STRIKETHROUGH
.strikethrough {
  text-decoration: line-through;
  color: #C72025; // variable colours.alert
  * {
    color: #C72025; // variable colours.alert
  }
}

// CODE BLOCKS
code {
  color: #CD375C;
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1.4;
  word-wrap: break-word;

  &.block {
    display: block;
    margin: 10px 0;
  }
}