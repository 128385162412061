// ------------------------------------------------------------ STYLE MODULE IMPORTS

@import './typography';
@import './transitions';

// ------------------------------------------------------------ GENERAL STYLES

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
}

#root {
  height: 100%;
}